import { Fragment, useCallback, useState } from "react";
import TemplateThumbnail from "../components/TemplateThumbnail";
import Layout from "../common/app.layout";
import { CREATE_WORKSPACE_COPY } from "../queries/personal";
import { useMutation } from "@apollo/client";
import {
  CREATE_ORG_WORKSPACE,
  MAKE_WORKSPACE_PRIVATE,
} from "../queries/configure";
import ErrorNotification from "../components/ErrorNotification";
import { getNotifications } from "../helpers/utils";
import { useNotifications } from "../contexts/notifications.context";
import { useTelemetry } from "../contexts/tacker.context";
import MessageModal from "../components/MessageModal";
import WorkspaceRenameInput from "../components/WorkspaceRenameInput";
import { Listbox, Transition } from "@headlessui/react";
import { useHistory } from "react-router-dom";

const defaultProjectId = "home";
const defaultTemplatesList = [
  {
    id: "blank",
    name: "Blank",
    selected: false,
  },
  {
    id: "5huTIYw7KYR",
    name: "Brainstorming Board",
    thumbnailURL: "/community-templates/brainstorming/5huTIYw7KYR.svg",
    selected: false,
  },
  {
    id: "3R5Q94E6mON",
    name: "Concept Map",
    thumbnailURL: "/community-templates/brainstorming/3R5Q94E6mON.svg",
    selected: false,
  },
  {
    id: "jos61nj48",
    name: "Agile Board",
    thumbnailURL: "/community-templates/projects/jos61nj48.svg",
    selected: false,
  },
  {
    id: "pGbeNzZWe7n",
    name: "Product Roadmap",
    thumbnailURL: "/community-templates/projects/pGbeNzZWe7n.svg",
    selected: false,
  },
  {
    id: "WjCt692M8uQ",
    name: "Business Capability Map",
    thumbnailURL: "/community-templates/strategy/WjCt692M8uQ.svg",
    premium: 1,
    selected: false,
  },
  {
    id: "cAUlraB2meh",
    name: "Swimlane Process Map",
    thumbnailURL: "/community-templates/strategy/cAUlraB2meh.svg",
    premium: 0,
    selected: false,
  },
  {
    id: "jrsog65m2",
    name: "Meeting Agenda",
    thumbnailURL: "/community-templates/meetings/jrsog65m2.svg",
    premium: 1,
    selected: false,
  },
  {
    id: "dFSGPB2RD7c",
    name: "Daily Standup",
    thumbnailURL: "/community-templates/meetings/dFSGPB2RD7c.svg",
    premium: 0,
    selected: false,
  },
  {
    id: "dRsCP5IMVWn",
    name: "Flowchart",
    thumbnailURL: "/community-templates/diagrams/dRsCP5IMVWn.svg",
    premium: 0,
    selected: false,
  },
  {
    id: "drzOI9bexnH",
    name: "Organizational Chart",
    thumbnailURL: "/community-templates/diagrams/drzOI9bexnH.svg",
    premium: 0,
    selected: false,
  },
  {
    id: "91vK8eaGtmB",
    name: "Sprint Retrospective",
    thumbnailURL: "/community-templates/research/91vK8eaGtmB.svg",
    premium: 1,
    selected: false,
  },
  {
    id: "jqhlqn953",
    name: "Lean UX Canvas",
    thumbnailURL: "/community-templates/research/jqhlqn953.svg",
    premium: 0,
    selected: false,
  },
  {
    id: "6TXGcb3QKyK",
    name: "UML Use Case",
    thumbnailURL: "https://svg.template.creately.com/6TXGcb3QKyK",
    premium: 0,
    selected: false,
  },
  {
    id: "g6lp80gw1",
    name: "Basic Network",
    thumbnailURL: "https://svg.template.creately.com/g6lp80gw1",
    premium: 1,
    selected: false,
  },
  {
    id: "jurvba3qf",
    name: "Family Genogram",
    thumbnailURL: "https://svg.template.creately.com/jurvba3qf",
    premium: 1,
    selected: false,
  },
  {
    id: "cw9gORUNhVH",
    name: "Concept Map",
    thumbnailURL: "https://svg.template.creately.com/cw9gORUNhVH",
    premium: 0,
    selected: false,
  },
  {
    id: "bWR4c0jH0fI",
    name: "Organization Chart",
    thumbnailURL: "https://svg.template.creately.com/bWR4c0jH0fI",
    premium: 0,
    selected: false,
  },
  {
    id: "hbPT2y1Pyky",
    name: "OKR Goal Setting",
    thumbnailURL: "https://svg.template.creately.com/hbPT2y1Pyky",
    premium: 1,
    selected: false,
  },
  {
    id: "GQa9pa5CaUW",
    name: "User Persona",
    thumbnailURL: "https://svg.template.creately.com/GQa9pa5CaUW",
    premium: 1,
    selected: false,
  },
  {
    id: "jtfjhdx11",
    name: "Ansoff Matrix Example",
    thumbnailURL: "https://svg.template.creately.com/jtfjhdx11",
    premium: 1,
    selected: false,
  },
  {
    id: "ZmlpomAvsqB",
    name: "Venn Diagram",
    thumbnailURL: "https://svg.template.creately.com/ZmlpomAvsqB",
    premium: 0,
    selected: false,
  },
  {
    id: "dNofguevDt7",
    name: "Learning Map",
    thumbnailURL: "https://svg.template.creately.com/dNofguevDt7",
    premium: 0,
    selected: false,
  },
];

export default function ConfigureHomePage() {
  const [selected, setSelected] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { telemetry } = useTelemetry();
  const { show } = useNotifications();
  const [documentCopy] = useMutation(CREATE_WORKSPACE_COPY);
  const [documentCreate] = useMutation(CREATE_ORG_WORKSPACE);
  const [makeWorkspacePrivate] = useMutation(MAKE_WORKSPACE_PRIVATE);
  const history = useHistory();

  const trackAction = useCallback(
    (source) => {
      return () => {
        telemetry.track("workspace.create", {
          value1Type: "Location",
          value1: source,
          value2Type: "App Context",
          value2: "Channel",
        });
      };
    },
    [telemetry]
  );

  async function createBlank(workspaceName = undefined) {
    const {
      data: { documentCreateWithPrivacyLevel: document },
    } = await documentCreate({
      errorPolicy: "none",
      variables: {
        documentInput: { name: workspaceName || "Untitled Workspace" },
      },
      onError: (error) => {
        const errors = getNotifications(error);
        errors.forEach(([title, message, type]) => {
          show(
            type === "custom" ? (
              <ErrorNotification error={{ title, message }} type={type} />
            ) : (
              message
            ),
            type
          );
        });
      },
      onCompleted: trackAction("Typical"),
    });
    return document.id;
  }

  async function createFromTemplate(workspaceName = undefined) {
    const selectedTemplate = defaultTemplatesList.find(
      (t) => t.id === selected
    );
    const {
      data: { documentCopy: document },
    } = await documentCopy({
      variables: {
        fromId: selectedTemplate.id,
        name: workspaceName,
        projectId: defaultProjectId,
      },
      onError: (error) => {
        const errors = getNotifications(error);
        errors.forEach(([title, message, type]) => {
          show(
            type === "custom" ? (
              <ErrorNotification error={{ title, message }} type={type} />
            ) : (
              message
            ),
            type
          );
        });
      },
      onCompleted: async ({ documentCopy: wkspResult }) => {
        trackAction("Config Template");
        await makeWorkspacePrivate({
          variables: { workspaceId: wkspResult.id },
        });
      },
    });
    return document.id;
  }

  return (
    <>
      <MessageModal
        title="Action not Permitted!"
        buttonText="Create Workspace"
        visible={isOpen}
        onClose={() => setIsOpen(false)}
      >
        You need to have a Team plan to use Creately inside Microsoft Teams
      </MessageModal>
      <Layout bgClass="bg-white">
        {/* <div className="flex items-center justify-between">
          <button
            className="flex gap-1 text-white items-center bg-creately-primary-dark border border-creately-primary-dark py-2 px-4 rounded"
            onClick={addUserToGroup}
          >
            Add to Group
          </button>
          <button className="flex gap-1 text-white items-center bg-creately-primary-dark border border-creately-primary-dark py-2 px-4 rounded">
            Create Workspace
          </button>
          <button className="flex gap-1 text-white items-center bg-creately-primary-dark border border-creately-primary-dark py-2 px-4 rounded">
            Create Workspace
          </button>
        </div> */}
        <div>
          <div className="my-2 min-h-40 py-2 px-4 rounded dark:bg-zinc-700">
            <Listbox value={selected} onChange={setSelected}>
              <Listbox.Button
                as="div"
                className="dark:text-white text-xs xs:text-sm font-semibold dark:font-normal tracking-wide pt-2 text-gray-500 mb-2"
              >
                Create a new workspace to get started
              </Listbox.Button>
              <Listbox.Options
                as="div"
                className="grid grid-cols-4 gap-3 pb-10"
                static
              >
                {defaultTemplatesList.map((template) => (
                  <Listbox.Option
                    as="div"
                    key={template.id}
                    value={template.id}
                  >
                    <TemplateThumbnail
                      template={template}
                      selected={selected === template.id}
                    />
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>

            <Transition
              key={selected}
              as={Fragment}
              show={!!selected}
              enter="transition ease-in-out duration-100 transform"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transition ease-in-out duration-100 transform"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <div className="fixed w-full bg-white dark:bg-msteams-dark border border-gray-200 dark:border-gray-800 shadow-md rounded-md p-3 translate-y-4 left-0 bottom-0">
                <WorkspaceRenameInput
                  onSubmit={(input) => {
                    selected === "blank"
                      ? createBlank(input.workspaceName).then((workspaceId) => {
                          history.push({
                            pathname: `/configure/workspaces`,
                            state: {
                              workspaceId,
                            },
                          });
                        })
                      : createFromTemplate(input.workspaceName).then(
                          (workspaceId) => {
                            history.push({
                              pathname: `/configure/workspaces`,
                              state: {
                                workspaceId,
                              },
                            });
                          }
                        );
                  }}
                  onCancel={() => setSelected("")}
                  defaultInput="Untitled Workspace"
                  placeholder={"Workspace name"}
                />
              </div>
            </Transition>
          </div>
        </div>
      </Layout>
    </>
  );
}
