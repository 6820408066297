import { gql } from "@apollo/client";

export const GET_ORG_WORKSPACES = gql`
  query OrgWorkspacesQuery(
    $pageNumber: Int
    $perPage: Int
    $order: SortOrder
    $groupId: String
  ) {
    team {
      documents(
        offset: $pageNumber
        limit: $perPage
        orderBy: $order
        groupId: $groupId
      ) {
        id
        name
        description
        type
        privacy
        status
        lastUpdated
        collaborators {
          user {
            id
            role
          }
        }
      }
    }
  }
`;

export const CREATE_ORG_WORKSPACE = gql`
  mutation CreateOrgWorkspace($documentInput: DocumentInput!) {
    documentCreateWithPrivacyLevel(
      document: $documentInput
      privacy: specific
      private: true
    ) {
      id
      name
    }
  }
`;

export const GET_ORG_FOLDERS = gql`
  query OrgFoldersQuery($order: SortOrder, $groupId: String) {
    team {
      folders(orderBy: $order, groupId: $groupId) {
        id
        name
      }
    }
  }
`;

export const CREATE_ORG_FOLDER = gql`
  mutation CreateOrgFolder($folderName: String!) {
    folderCreate(name: $folderName, privacy: specific) {
      id
      name
    }
  }
`;

export const SET_FOLDER_GROUP_PRIVACY = gql`
  mutation SetFolderGroupPrivacy($folderId: String!, $groupId: String!) {
    folderSetGroupPrivacy(id: $folderId, groupId: $groupId, privacy: private)
  }
`;

export const SET_WORKSPACE_GROUP_PRIVACY = gql`
  mutation SetWorskpaceGroupPrivacy($workspaceId: String!, $groupId: String!) {
    documentSetGroupPrivacy(
      id: $workspaceId
      groupId: $groupId
      privacy: private
    ) {
      id
    }
  }
`;

export const SET_FOLDER_PRIVACY = gql`
  mutation SetFolderPrivacy($folderId: String!) {
    folderSetPrivacy(id: $folderId, privacy: teamLink)
  }
`;

export const SET_WORKSPACE_PRIVACY = gql`
  mutation SetWorskpacePrivacy($workspaceId: String!) {
    documentSetPrivacy(id: $workspaceId, privacy: teamLink) {
      id
    }
  }
`;

export const MAKE_WORKSPACE_PRIVATE = gql`
  mutation SetWorskpacePrivacy($workspaceId: String!) {
    documentSetPrivacy(id: $workspaceId, privacy: specific) {
      id
    }
  }
`;

export const FIND_ORG_WORKSPACES = gql`
  query FindWorkspace($name: String!, $orgId: String) {
    searchDocument(name: $name, organizationId: $orgId, type: "organization") {
      id
      name
      privacy
      status
      lastUpdated
    }
  }
`;

export const FIND_ORG_FOLDERS = gql`
  query FindFolder($name: String!) {
    searchDocument(name: $name) {
      id
      name
      lastUpdated
    }
  }
`;

export const RENAME_WORKSPACE = gql`
  mutation RenameWorkspace($id: String!, $name: String!) {
    documentRename(id: $id, name: $name)
  }
`;

export const RENAME_FOLDER = gql`
  mutation RenameFolder($id: String!, $name: String!) {
    folderRename(id: $id, name: $name)
  }
`;
